body {
  background-color: #001524;
  color: #fbfef9;
  font-family: "system-ui", Courier, monospace;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.App {
  max-width: 600px;
  margin: 2vh auto;
  padding: 5px 20px 20px 20px;
  border: 5px solid #0e79b2;
  border-radius: 10px;
  background: #0b0909;
}

.chat-header {
  display: grid;
  grid-auto-flow: column;
  margin-bottom: 5px;
}

.chat-header b {
  color: #ffffff;
  margin-left: -40px;
}

.connection-status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #d32f2f; /* Default: Red (offline) */
  border: 2px solid #ffffff;
  transition: background-color 0.3s ease;
  margin-top: 3px;
}

.connection-status.online {
  background-color: #66bb6a; /* Green (online) */
}

.connection-status.offline {
  background-color: #d32f2f; /* Red (offline) */
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 85vh;
  background: #1e1e1e;
  border: 2px solid #0e79b2;
  border-radius: 10px;
  overflow: hidden;
}

.messages {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #ffffff #191923;
}

.messages::-webkit-scrollbar {
  width: 8px;
}

.messages::-webkit-scrollbar-track {
  background: #191923;
}

.messages::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid #191923;
}

.messages::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
}

.message {
  display: inline-block;
  max-width: 80%;
  padding: 10px;
  border-radius: 10px;
  background: #0d0b0b;
  border: 2px solid #0e79b2;
  color: #fbfef9;
  font-size: 14px;
  margin-bottom: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.message.user {
  align-self: flex-end;
  background: #0d0b0b;
}

.message.bot {
  align-self: flex-start;
  background: #0d0b0b;
}

.chat-form {
  display: flex;
  border-top: 2px solid #0e79b2;
}

.chat-form textarea {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 0;
  background: #2c2c2c;
  color: #fbfef9;
  font-size: 14px;
  font-family: "system-ui", Courier, monospace;
  resize: none;
  height: 50px;
  line-height: 1.4;
}

.chat-form button {
  padding: 10px;
  background: #0e79b2;
  color: #fbfef9;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s;
}

.chat-form button:hover {
  background: #0c5a8a;
}